<template>
    <v-card style="" flat>
        <v-row class="px-3">
            <v-col cols="12" md="9" class="">
                <v-card min-height="80vh" flat>
                    <v-img :src="content.avatar "></v-img>
                    <v-card-title class="title mb-1 green--text text--darken-4">
                        {{content.title }}
                    </v-card-title>
                    <v-card-subtitle class="caption">{{content.createdAt}}</v-card-subtitle>
                    <v-card-subtitle class="caption" v-html="content.brief"></v-card-subtitle>
                    <v-card-text class="mx-4" id="body" lazy>
                        <span v-html="content.body" ref="body">
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card height="400px" flat outlined color="amber lighten-5">
                    <v-card-title>
                        Bài viết liên quan
                    </v-card-title>
                    <v-sheet class="d-flex flex-column">
                        
                    </v-sheet>
                    <v-card-text></v-card-text>
                </v-card>
            </v-col>
        </v-row>
        
        <v-overlay :value="loading" class="amber--text">
            <h3 class="underline">
                Đang tải bài viết ....
            </h3>
        </v-overlay>
    </v-card>
</template>
<script>
const NEWS_URL = "contents";
import MyBreadcrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import moment from 'moment';
export default {
    name: "NewsDetail",
    props: ["breadcrumbs", "id"],
    components:{
        MyBreadcrumb,
    },
    data(){
        return {
            content: "",
            loading: true,
        }
    },
    methods:{
        diffToHuman(value){
            let date = moment(value, "YYYY-MM-DD").locale("vi");
            return moment(date).fromNow();
        },
        fetchData() {
            let url = `${NEWS_URL}/${this.$route.params.id}`
            return http.get(url).then(res => {
                this.content = Object.assign({}, res.data);
                this.loading = false;
            });
        }
    },
    created(){
        this.fetchData();
    },
    updated() {
        let imgs = this.$refs.body.getElementsByTagName("img");
        for (let img of imgs) {
            img.addEventListener('click', function(event) {
                event.preventDefault();
                let url = img.getAttribute('src');
                window.open(url,'Image','width=largeImage.stylewidth,height=largeImage.style.height,resizable=1');
            });
        }
    },
}
</script>
<style scoped>
    ::v-deep figcaption {
        text-align: center;
        font-size: small;
        font-style: italic;
    }
</style>
